* {
  margin: 0;
  color: #707070;
}

.content {
  margin: 0 auto;
  padding: 10px;
  width: 96%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}